import Image from 'next/image'
import { FC } from 'react'

import { MobileMenuGroup, StackMenuGroup } from '@/sections/NavBar/MenuGroup'

const platform = [
    {
        url: '/fund-climate-projects',
        label: 'Fund climate projects',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-carbon.png`}
                alt={`Fund climate projects`}
            />
        ),
    },
]

const api = [
    {
        url: '/embed-emission-calculations',
        label: 'Calculate customer emissions',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-embed-calcs.png`}
                alt={`Track and report customer emissions`}
            />
        ),
    },
    {
        url: '/embed-climate-contributions',
        label: 'Compensate customer emissions',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-embed-carbon.png`}
                alt={`Compensate customer emissions`}
            />
        ),
    },
]

export const ProductPlatformMenu: FC = () => {
    return <StackMenuGroup header={`For your business`} items={platform} width={400} />
}

export const ProductAPIMenu: FC = () => {
    return <StackMenuGroup header={`For your customers`} items={api} width={400} />
}

export const ProductMobileMenu: FC<{ onClose: () => void }> = ({ onClose }) => {
    return <MobileMenuGroup items={[...api, ...platform]} onClose={onClose} />
}
