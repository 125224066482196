'use client'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import Image from 'next/image'

import Link from '@/components/Link'
import Logo from '@/components/Logo'

const Footer = () => {
    return (
        <Box mb={10}>
            <div>
                <Grid
                    container
                    spacing={{
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 3,
                    }}
                    columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}
                    justifyContent="center"
                    alignItems="flex-start"
                    height="100%"
                    style={{
                        marginTop: '0px',
                    }}
                >
                    <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            pt={{
                                xs: 0,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3,
                            }}
                        >
                            <Box mb={3}>
                                <Logo />
                            </Box>
                            <Link href="/terms/" color={'Grey700'} variant="caption">
                                Terms &amp; Conditions
                            </Link>
                            <Link href="/privacy/" color={'Grey700'} variant="caption">
                                Privacy Policy
                            </Link>
                            <Text color={'Grey700'} variant="caption" component="span">
                                &copy; {new Date().getFullYear()} Lune Climate Ltd. All rights
                                reserved.
                            </Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={8} md={8} lg={8} xl={8}>
                        <Grid
                            container
                            spacing={{
                                xs: 4,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3,
                            }}
                            columns={{ xs: 4, sm: 8, md: 8, lg: 8, xl: 8 }}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            height="100%"
                            style={{
                                marginTop: '0px',
                            }}
                        >
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box
                                    mb={{
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                    }}
                                >
                                    <Text color={'Grey900'} variant="h6" component="span">
                                        Product
                                    </Text>
                                </Box>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/fund-climate-projects/"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Fund climate projects
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/embed-emission-calculations"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Embed emission calculations
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/embed-climate-contributions"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Embed climate contributions
                                            </Link>
                                        }
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box
                                    mb={{
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                    }}
                                >
                                    <Text color={'Grey900'} variant="h6" component="span">
                                        Solutions
                                    </Text>
                                </Box>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/fund-climate-projects"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Buy carbon credits
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/logistics"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Logistics
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/procurement"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Procurement
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/spend-management"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Spend management
                                            </Link>
                                        }
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box
                                    mb={{
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                    }}
                                >
                                    <Text color={'Grey900'} variant="h6" component="span">
                                        Resources
                                    </Text>
                                </Box>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/resource-hub"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Resource hub
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/resource-hub/eu-ets-surcharge-explorer"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                EU ETS surcharge explorer
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/customers"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Customer stories
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="https://docs.lune.co"
                                                color={'Grey700'}
                                                variant="caption"
                                                newPage
                                            >
                                                API documentation
                                            </Link>
                                        }
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box
                                    mb={{
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                    }}
                                >
                                    <Text color={'Grey900'} variant="h6" component="span">
                                        Company
                                    </Text>
                                </Box>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/about/"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                About
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        primary={
                                            <Link
                                                href="https://twitter.com/luneclimate"
                                                color={'Grey700'}
                                                variant="caption"
                                                newPage
                                            >
                                                Twitter
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="https://www.linkedin.com/company/lune-climate"
                                                color={'Grey700'}
                                                variant="caption"
                                                newPage
                                            >
                                                Linkedin
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="https://lune.co/join-us"
                                                color={'Grey700'}
                                                variant="caption"
                                                newPage
                                            >
                                                Join us 🚀
                                            </Link>
                                        }
                                    />
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary={
                                            <Link
                                                href="/contact-us"
                                                color={'Grey700'}
                                                variant="caption"
                                            >
                                                Contact us
                                            </Link>
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                        <Box
                            mt={3}
                            sx={{
                                display: 'flex',
                                gap: {
                                    xs: '32px',
                                    sm: '32px',
                                    md: '24px',
                                    lg: '24px',
                                    xl: '24px',
                                },
                                alignItems: 'center',
                                flexDirection: {
                                    xs: 'row',
                                    sm: 'row',
                                    md: 'column',
                                    lg: 'column',
                                    xl: 'column',
                                },
                            }}
                        >
                            <Image
                                width={42.37}
                                height={44}
                                alt={`EBA winner logo`}
                                src={'/images/footer/EBA-winner-logo.png'}
                            />
                            <Image
                                width={49.18}
                                height={40}
                                alt={`ISO logo`}
                                src={'/images/footer/ISO-logo.png'}
                            />
                            <Image
                                width={69.82}
                                height={32}
                                alt={`GLEC logo`}
                                src={'/images/footer/GLEC-logo.png'}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    )
}

export default Footer
