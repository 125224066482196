import { useCallback, useEffect, useState } from 'react'

const FALLBACK = 500000

/**
 * Returns a random integer between min and max inclusive
 */
function randomBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const usePayhawkNumCalcs = ({
    intervalSeconds,
}: {
    intervalSeconds: number
}): { prev: number; current: number } | undefined => {
    const [numCalculations, setNumCalculations] = useState<
        { prev: number; current: number } | undefined
    >(undefined)

    const updateCounter = useCallback(async () => {
        try {
            const response = await fetch(process.env.NEXT_PUBLIC_PAYHAWK_STATS_URL!)
            const { num_calculations: calcs } = await response.json()
            setNumCalculations((prev) => {
                if (prev === undefined) {
                    // start slighty behind
                    return { prev: Math.max(calcs - randomBetween(10, 30), 0), current: calcs }
                }
                return calcs > prev.current ? { prev: prev.current, current: calcs } : prev
            })
        } catch {
            // fallback to something static
            setNumCalculations({ prev: FALLBACK, current: FALLBACK })
        }
    }, [])

    useEffect(() => {
        let killed = false

        async function poll() {
            if (killed) {
                return
            }
            await updateCounter()
            setTimeout(poll, intervalSeconds * 1000)
        }

        poll()

        return () => {
            killed = true
        }
    }, [updateCounter, intervalSeconds])

    return numCalculations
}

export default usePayhawkNumCalcs
