import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

export interface ApiReferenceSectionProps {
    children: React.ReactNode[]
    sx?: SxProps
}

const ApiReferenceSection = ({ children, sx }: ApiReferenceSectionProps): JSX.Element => {
    if (children.length !== 2) {
        throw new Error(`ApiReferenceSection accepts two children only, given ${children.length}`)
    }

    const leftColumnRef = useRef<HTMLDivElement>(null)
    const [leftColumnHeight, setLeftColumnHeight] = useState<number | null>(null)

    useEffect(() => {
        const updateHeight = () => {
            if (leftColumnRef.current) {
                setLeftColumnHeight(leftColumnRef.current.offsetHeight)
            }
        }

        // Initial height calculation
        updateHeight()

        // Recalculate height on window resize
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    return (
        <Box
            sx={{
                maxHeight: '100%',
                overflow: 'auto',
                mt: 4,
                mb: 4,
                ...sx,
                '.MuiTypography-h5:first-of-type': {
                    mt: 0,
                },
            }}
        >
            <Grid
                container
                spacing={3}
                sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    flexWrap: {
                        xs: 'wrap',
                        md: 'nowrap',
                    },
                }}
            >
                <Grid
                    ref={leftColumnRef}
                    item
                    sx={{
                        minWidth: {
                            md: '400px',
                        },
                    }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    {children[0]}
                </Grid>
                <Grid
                    item
                    sx={{
                        minWidth: {
                            md: '400px',
                        },
                        position: 'sticky',
                        top: '0',
                        height: {
                            xs: 'auto',
                            md: leftColumnHeight ? `${leftColumnHeight}px` : 'none',
                        },
                    }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    {children[1]}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ApiReferenceSection
